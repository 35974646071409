



























































































































import Class from './c-widget-date';
export default Class;
