import { Component, Vue } from 'vue-property-decorator';

import i18n from '@/services/i18n';

import { accessor } from '../store';
import { ISliderData } from '../store/types';
import theme from '@/services/theme';


const today = new Date().clearTimePart();


type TZoom = 'years' | 'months' | 'days';

interface IYearItem {
    text: string;
    value: number;
    disabled: boolean;
}

interface IMonthItem {
    text: string;
    value: number;
    disabled: boolean;
}

interface ISliderTemplateData {
    values: number[];
    value: number;
    valueText: string;
}


const capitalize = (text: string): string => {
    if (text.length === 0) {
        return '';
    }
    if (text.length === 1) {
        return text.toUpperCase();
    }
    return text.substring(0, 1).toUpperCase() + text.substring(1).toLowerCase();
};


@Component
export default class CWidgetDate extends Vue {
    private windowClickListener: undefined | ((this: Window, ev: MouseEvent) => void);
    public srcBack = '/img/light-theme/reset_date_light.svg';

    public created() {
        this.$watch('themeDark', () => {
            if (theme.dark) {
                this.srcBack = '/img/dark-theme/reset_date_dark.svg';
            } else {
                this.srcBack = '/img/light-theme/reset_date_light.svg';
            }
        });
    }

    public mounted() {
        if (theme.dark) {
            this.srcBack = '/img/dark-theme/reset_date_dark.svg';
        } else {
            this.srcBack = '/img/light-theme/reset_date_light.svg';
        }
    }

    // переключение темы
    private get themeDark(): boolean {
        return theme.dark;
    }

    private get monthFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, { month: 'long' });
    }

    private getMonthName(date: Date): string {
        return capitalize(this.monthFormat.format(date));
    }

    private get locale(): string {
        return i18n.locale;
    }


    // #region store data
    private get storeData(): null | ISliderData {
        return accessor.sliderData;
    }

    private set storeData(value: null | ISliderData) {
        accessor.sliderData = value;
    }

    private get storeValue(): Date {
        const data = this.storeData;
        if (data !== null) {
            return data.value;
        }

        return today;
    }

    private set storeValue(value: Date) {
        const data = this.storeData;
        if (data === null) {
            // eslint-disable-next-line no-console
            console.warn('Slider data is not initialized yet');
        } else {
            let newValue = value;
            if (newValue < data.min) {
                newValue = data.min;
            } else if (newValue > data.max) {
                newValue = data.max;
            }

            const newData: ISliderData = {
                changedByUser: true,
                min: data.min,
                max: data.max,
                value: newValue
            };

            accessor.sliderData = newData;
        }
    }

    private get storeMinValue(): Date {
        const data = this.storeData;
        if (data !== null) {
            return data.min;
        }
        return this.storeValue;
    }

    private get storeMaxValue(): Date {
        const data = this.storeData;
        if (data !== null) {
            return data.max;
        }

        return this.storeValue;
    }
    // #endregion


    // #region datepicker - defaultPopperProps and string datepicker data
    private defaultPopperProps = {
        popperOptions: {
            modifiers: {
                preventOverflow: {
                    padding: 20
                }
            },
            onUpdate: function (data: unknown) {
                //
            }
        }
    };

    private get storeMinValueString(): string {
        const date = this.storeMinValue;
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    private get storeMaxValueString(): string {
        const date = this.storeMaxValue;
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    private get storeValueString(): string {
        const date = this.storeValue;

        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    private set storeValueString(value: string) {
        const stringParts = value.split('-');
        if (stringParts.length !== 3) {
            throw new Error(`Date string contains ${stringParts.length} part(s) (expected - 3)`);
        }

        const year = parseInt(stringParts[0]);
        const month = parseInt(stringParts[1]) - 1;
        const day = parseInt(stringParts[2]);

        const date = new Date(year, month, day);

        if (this.storeValue !== date) {
            this.storeValue = date;
        }
    }
    // #endregion


    // #region desktop - zoom
    private zoom: TZoom = 'years';


    // #region years
    private get zoomYears(): IYearItem[] {
        const min = this.storeMinValue.getFullYear();
        const max = this.storeMaxValue.getFullYear();

        const result: IYearItem[] = [];
        for (let year = min; year <= max; year++) {
            result.push({
                text: year.toString(),
                value: year,
                disabled: false
            });
        }
        return result;
    }

    private get zoomYear(): number {
        return this.storeValue.getFullYear();
    }

    private set zoomYear(value: number) {
        let newYear: number;
        if (typeof value === 'string') {
            newYear = parseInt(value);
        } else {
            newYear = value;
        }

        let date = new Date(this.storeValue);
        const month = date.getMonth();
        date.setFullYear(newYear);
        if (date.getMonth() > month) {
            date.setDate(0);
        }

        if (date < this.storeMinValue) {
            date = this.storeMinValue;
        }
        if (date > this.storeMaxValue) {
            date = this.storeMaxValue;
        }

        this.storeValue = date;
    }
    // #endregion


    // #region months
    private get zoomMonths(): IMonthItem[] {
        const date = new Date(this.storeValue);
        date.setDate(1);

        const result: IMonthItem[] = [];
        for (let i = 0; i < 12; i++) {
            date.setMonth(i);

            const min = new Date(date);
            min.setDate(1);

            const max = new Date(date);
            max.setDate(1);
            max.setMonth(max.getMonth() + 1);
            max.setMilliseconds(-1);

            const item: IMonthItem = {
                text: this.getMonthName(date),
                value: date.getMonth(),
                disabled: (max < this.storeMinValue) || (min > this.storeMaxValue)
            };
            result.push(item);
        }
        return result;
    }

    private get zoomMonth(): number {
        return this.storeValue.getMonth();
    }

    private set zoomMonth(value: number) {
        let newMonth: number;
        if (typeof value === 'string') {
            newMonth = parseInt(value);
        } else {
            newMonth = value;
        }

        let date = new Date(this.storeValue);
        date.setMonth(newMonth);
        if (date.getMonth() !== newMonth) {
            date.setDate(0);
        }

        if (date < this.storeMinValue) {
            date = this.storeMinValue;
        }
        if (date > this.storeMaxValue) {
            date = this.storeMaxValue;
        }

        this.storeValue = date;
    }
    // #endregion


    // #region slider data
    private get sliderData(): ISliderTemplateData {
        const format = new Intl.DateTimeFormat(this.$i18n.locale, { year: 'numeric', month: 'long', day: 'numeric' });
        const date = new Date(this.storeValue);
        let dateValue: number;
        const values: number[] = [];
        let value: number;
        const valueText = format.format(date);

        switch (this.zoom) {
            case 'days':
                value = date.getDate();
                // valueText = value.toString();

                date.setDate(1);

                if (date < this.storeMinValue) {
                    date.setTime(this.storeMinValue.getTime());
                }

                dateValue = date.getMonth();

                while ((date <= this.storeMaxValue) && (date.getMonth() === dateValue)) {
                    values.push(date.getDate());
                    date.setDate(date.getDate() + 1);
                }

                break;
            case 'months':
                value = date.getMonth();
                // valueText = this.getMonthName(date);

                date.setDate(1);
                date.setMonth(0);

                if (date < this.storeMinValue) {
                    date.setTime(this.storeMinValue.getTime());
                }

                dateValue = date.getFullYear();
                while ((date <= this.storeMaxValue) && (date.getFullYear() === dateValue)) {
                    values.push(date.getMonth());
                    date.setMonth(date.getMonth() + 1);
                }

                break;
            default:
                value = date.getFullYear();
                // valueText = value.toString();

                date.setDate(1);
                date.setMonth(0);
                date.setFullYear(this.storeMinValue.getFullYear());

                while (date <= this.storeMaxValue) {
                    values.push(date.getFullYear());
                    date.setFullYear(date.getFullYear() + 1);
                }

                break;
        }

        return { values, value, valueText };
    }

    private get sliderMin(): number {
        return this.sliderData.values[0];
    }

    private get sliderMax(): number {
        return this.sliderData.values[this.sliderData.values.length - 1];
    }

    private get sliderValue(): number {
        return this.sliderData.value;
    }

    private set sliderValue(value: number) {
        const date = new Date(this.storeValue);
        const month = date.getMonth();

        switch (this.zoom) {
            case 'days':
                date.setDate(value);
                break;
            case 'months':
                date.setMonth(value);
                while (date.getMonth() > value) {
                    date.setDate(0);
                }
                break;
            default:
                date.setFullYear(value);
                if (date.getMonth() > month) {
                    date.setDate(0);
                }
                break;
        }

        if (this.storeValue.notEquals(date)) {
            this.storeValue = date;
        }
    }
    // #endregion


    private setToday() {
        const date = new Date().clearTimePart();

        if (date < this.storeMinValue) {
            date.setTime(this.storeMinValue.getTime());
        } else if (date > this.storeMaxValue) {
            date.setTime(this.storeMaxValue.getTime());
        }

        if (this.storeValue.notEquals(date)) {
            this.storeValue = date;
        }
    }
}